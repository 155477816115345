import { createSlice } from '@reduxjs/toolkit'
import API from 'app/services/API'
import { USER_MEL } from '../constants'



const initialState = {
    value: [],
}

export const userDetails = createSlice({
    name: 'user',
    initialState,
    reducers: {

        incrementByAmount: (state, action) => {
            console.log(action)
            state.value = action.payload
        },
    },
})
export const { incrementByAmount } = userDetails.actions


export const user_details = () => async(dispatch) => {

    const response = await API.get(USER_MEL);
    if (response.status === 200) {


        dispatch(incrementByAmount(response.data))
    }


}

// Action creators are generated for each case reducer function


export default userDetails